import React, { useEffect, useState } from 'react';
import {PlanFilterContext} from "../PlanFilterContext";
import * as _ from 'lodash';
import {
  Box,
  Card, Collapse, Grid,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { colors } from '@coverright/ui/themes';
import {  DateInput } from '@coverright/ui/inputs';
import { getLabelForCategory, planCategories, PlanCategory } from '../../category/mgcategory';
import { StyledSelect } from './StyledSelect';
import { Gender } from '@coverright/data-access/types/medigap';
import { isEffectiveDateValid } from '../utils/utils';
import { useIsMAState, useIsWIState } from '@coverright/data-access/medicare';
import moment from 'moment/moment';
import { getStoredUser } from '@coverright/utils';


export default function PlanTopFilter(props: {zip: string, goToCategory: () => void}) {
  const filter = React.useContext(PlanFilterContext)
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));
  const isMassachusetts = useIsMAState(props.zip);
  const isWisconsin = useIsWIState(props.zip);
  const [selectedPlanCategory, setSelectedPlanCategory] = React.useState<PlanCategory | 'all'>();

  const [collapsed, setCollapsed] = React.useState(false);

  React.useEffect(() => {
    if (filter.values?.planNames) {
      const allSelected = _.flatMap(planCategories, c => c.plans).every(plan => filter.values!.planNames!.includes(plan));
      if (allSelected) {
        setSelectedPlanCategory('all');
      } else {
        const cat = planCategories.find(c => filter.values?.planNames.every(p => c.plans.includes(p)))
        if (cat) {
          setSelectedPlanCategory(cat.name)
        } else {
          setSelectedPlanCategory(filter.values?.selectedPlanCategory)
        }
      }
    }
  }, [filter.values])

  return <>
    {(isMassachusetts === false && isWisconsin === false) && <Card sx={{
      background: colors.text.primary,
      boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.15)`,
      borderLeft: '10px solid #5ECB9E',
      p: {xs: '6px 20px 6px 12px', md: '6px 6px 6px 20px'}
    }}>
      <Box sx={{display: 'flex', gap: 1, flexDirection: {xs: 'column', sm: 'row'}, alignItems: {sm: 'center'}}}>
        <Box sx={{display: 'flex', flex: 1}}>
          <Typography sx={{color: 'white', fontSize: {xs: 16, md: 20}, lineHeight: {xs: '20px', md: '25px'}}} className={'bold ls-1'}>Select a plan category:</Typography>
        </Box>
        <Box sx={{display: 'flex', flex: 1}}>
          {selectedPlanCategory && (
            <StyledSelect label={''}
                          hideTick
                          data-test={'category'}
                          sx={{width: 1, maxWidth: {sm: 240}}}
                          value={selectedPlanCategory}
                          onChange={event => {
                            filter.switchCategory(event.target.value as PlanCategory);
                          }}
            >
              {planCategories.map((cat, index) => <MenuItem data-test={`category-${index}`} key={cat.name} value={cat.name}>{cat.name} ({cat.plans.join(', ')})</MenuItem>)}
              <MenuItem data-test={`category-all`} value={'all'}>All plans</MenuItem>
            </StyledSelect>
          )}
        </Box>
        <Box sx={{display: 'flex', flex: 1, justifyContent: 'center'}}>
          <Typography sx={{color: 'white'}} className={'fs-16 lh-20'}
                      onClick={props.goToCategory}>
            <span className={'medium ls-1'}>Not sure?</span> <Box data-test={'mg_open_compare'} component={'span'} sx={{color: colors.custom.green.variant1, fontWeight: 700}} className={'pointer underline'}>Compare plans</Box>
          </Typography>
        </Box>
      </Box>
    </Card>}
    {((isMassachusetts === false && isWisconsin === false) && filter.values?.selectedPlanCategory !== 'all') && <Card sx={{
      background: 'white',
      borderRadius: '0px 0px 10px 10px',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
      padding: '11px 20px',
      position: 'relative',
    }}>
      <Collapse collapsedSize={23} in={!collapsed}>
        {getLabelForCategory({
          cat: filter.values?.selectedPlanCategory as PlanCategory,
          sx: {
            '& ul': {
              marginBlockStart: 0,
              marginBlockEnd: 0,
              paddingInlineStart: 0,
              listStyle: 'inside',
              '& li': {
                color: colors.text.primary,
                lineHeight: '20px',
                fontSize: 14
              }
            }
          },
          skipTitle: false,
          titleClassName: 'fs-14 bold mb-10',
          age: filter.values?.age
        })}
      </Collapse>

      <Box sx={{background: 'white', width: '100%', height: '11px', position: 'absolute', bottom: 0}} />
      <Box sx={{display: 'flex', alignItems: 'center', position: 'absolute', top: 15, right: 26, cursor: 'pointer'}} onClick={() => setCollapsed(!collapsed)} >
        <Typography className={'fs-14 lh-14'}>Details</Typography>
        <Box component={'span'} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', maxHeight: '14px', ml: '5px', transition: 'all 0.3s', transform: `rotate(${collapsed ? '-180' : '0'}deg)`}}><img src={'/assets/img/arrow.svg'} /></Box>
      </Box>
    </Card>}
    {isMassachusetts && <Card sx={{
      background: 'white',
      borderRadius: '0px 0px 10px 10px',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
      padding: '11px 20px',
      position: 'relative',
    }}>
      <Collapse collapsedSize={23} in={!collapsed}>
        <>
          <Typography className={'fs-14 bold'}>Medicare Supplement Policies in Massachusetts</Typography>
          <Typography className={'fs-14 mt-10'}>If you live in Massachusetts, you have guaranteed issue rights to buy a Medicare Supplement policy, but the policies are not standardized as they are in other states. Instead, you can choose from a Medicare Core or Medicare Supplement 1 (if eligible) or 1A Plan.</Typography>
        </>
      </Collapse>

      <Box sx={{background: 'white', width: '100%', height: '11px', position: 'absolute', bottom: 0}} />
      <Box sx={{display: 'flex', alignItems: 'center', position: 'absolute', top: 15, right: 26, cursor: 'pointer'}} onClick={() => setCollapsed(!collapsed)} >
        <Typography className={'fs-14 lh-14'}>Details</Typography>
        <Box component={'span'} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', maxHeight: '14px', ml: '5px', transition: 'all 0.3s', transform: `rotate(${collapsed ? '-180' : '0'}deg)`}}><img src={'/assets/img/arrow.svg'} /></Box>
      </Box>
    </Card>}

    <Card className={`mt-16 pv-8 ph-20`}>
      <div className={'flex-space-between'}>
        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, width: 1, alignItems: {md: 'center'}, gap: '20px'}}>
          <Box sx={{display: 'flex', alignItems: 'center', gap: '20px'}}>
            <Box sx={{flex: {xs: 1, md: 'unset'}, display: 'flex', alignItems: 'center'}}>
              {!phone && <div className={'mr-8'}>
                <Typography className={'medium fs-14 lh-17 ls-1'}>Age</Typography>
                <Typography className={'fs-11 lh-13 no-wrap'}>at effective date</Typography>
              </div>}
              <StyledSelect label={phone ? 'Age at effective date' : ''}
                            data-test={'age'}
                            hideTick
                            placeholder={'Select your age'}
                            labelClassName={'fs-12 lh-15'}
                            className={!phone ? 'w-85' : ''}
                            value={filter.values?.age ? (filter.values?.age < 64 ? 64 : filter.values?.age) : ''}
                            onChange={event => {
                              filter.switchAge(event.target.value as number)
                            }}
              >
                <MenuItem value={64}>&lt;64</MenuItem>
                {_.fill(Array(50), 2).map((p, i) => <MenuItem key={i} value={i + 65}>{i + 65}</MenuItem>)}
              </StyledSelect>
            </Box>
            <Box sx={{flex: {xs: 1, md: 'unset'}, display: 'flex', alignItems: 'center'}}>
              {!phone && <Typography color={'textPrimary'} className={'medium fs-14 mr-8 ls-1'}>Gender</Typography>}
              <StyledSelect label={phone ? 'Gender' : ''}
                            data-test={'gender'}
                            hideTick
                            placeholder={'Select your gender'}
                            labelClassName={'fs-12 lh-15'}
                            className={!phone ? 'w-85' : ''}
                            value={filter.values?.gender || ''}
                            onChange={event => {
                              filter.switchGender(event.target.value as Gender)
                            }}
              >
                <MenuItem value={Gender.M}>Male</MenuItem>
                <MenuItem value={Gender.F}>Female</MenuItem>
              </StyledSelect>
            </Box>
          </Box>
          <Box sx={{display: 'flex', alignItems: 'center', gap: '20px'}}>
            <Box sx={{flex: {xs: 1, md: 'unset'}, display: 'flex', alignItems: 'center'}}>
              {!phone && <Typography color={'textPrimary'} className={'medium fs-14 mr-8 ls-1'}>Tobacco?</Typography>}
              <StyledSelect label={phone ? 'Tobacco?' : ''}
                            data-test={'tobacco'}
                            hideTick
                            className={!phone ? 'w-85' : ''}
                            labelClassName={'fs-12 lh-15'}
                            value={filter.values?.tobacco?.toString() || ''}
                            onChange={event => {
                              filter.toggleTobacco(event.target.value === 'true' ? true : false)
                            }}
              >
                <MenuItem value={'false'}>No</MenuItem>
                <MenuItem value={'true'}>Yes</MenuItem>
              </StyledSelect>
            </Box>
            <Box sx={{flex: {xs: 1, md: 'unset'}, display: 'flex', alignItems: 'center'}}>
              <EffectiveDate value={filter.values?.effectiveDate ? moment(filter.values?.effectiveDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}
                             onChange={value => filter.switchEffectiveDate(value ? moment(value, 'MM/DD/YYYY').format('YYYY-MM-DD') : undefined)} />
            </Box>
          </Box>
        </Box>
      </div>
    </Card>

  </>;
}


const EffectiveDate = (props: {value: string, onChange: (value: string) => void} ) => {
  const [value, setValue] = useState(props.value);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!value && props.value) {
      setValue(props.value);
    }
  }, [value, props.value]);

  return <>
    {!phone && <Typography color={'textPrimary'} className={'medium fs-14 mr-8 no-wrap ls-1'}>Effective date</Typography>}
    <DateInput
      wrapperSx={{'& .MuiFormControl-root': {mb: 0}}}
      label={phone ? 'Effective date' : ''}
      className={'mb-0'}
      placeholder={''}
      hideTick
      selectAllOnFocus
      inputProps={{
        inputLabelClass: 'fs-12 lh-15',
      }}
      InputProps={{
        classes: {
          input: 'fs-14 regular pv-8 no-wrap ph-10',
        },
        endAdornment: undefined
      }}
      disabled={!!sessionStorage.getItem('y2y')}
      value={value}
      onChange={e => {
        setValue(e.target.value);
        if (!e.target.value || isEffectiveDateValid(e.target.value)) {
          props.onChange(e.target.value);
        }
      }}
    />
  </>
}
